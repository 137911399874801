import React from 'react'

import Layout from '../../layout/Layout';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Typography, Grid } from '@mui/material';

import Iphelper from '../../helpers/Iphelper';

import Navbar from '../../utilities/Navbar';
import Navbarv3 from '../../utilities/Navbarv3/Navbarv3';
import Footerv2 from '../../utilities/Footerv2'

import image from './images/blue-computer-closing-resize.jpg'


function Blog() {

  return (
    <Container sx={{
      bgcolor: '#E7ECEF',
      pt: 2,
      pb: 2,
      mb: 2,
    }}
    component="main" maxWidth="xl">
      <Navbarv3 />
      <Box sx={{
        mt: 2, 
        display: 'flex',
        bgcolor: '#fff',
        alignItems: 'center'
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              flexDirection: 'column',
            }}>
              <Typography variant="h3" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
                letterSpacing: '.1rem'
              }}>
                IPTooling Blog
              </Typography>
              <Typography variant="body1" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
                pt: 5
              }}>
                Learn about technologies used throughout the world in common tech stacks and find answers to common questions here. Click through any blog to learn more about topics. 
              </Typography>
            </Box>
            <Grid item xs={12}>
              <Iphelper />
            </Grid>
            
            
          </Grid>
        </Grid>
      </Box>
      <Footerv2 />
    </Container>
  )
}

export default Blog