import './App.css';
import React from "react";

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Ip_config from "./pages/Ip_config";
import Ip_config_v2 from "./pages/Ip_config_v2";
import Home from "../src/pages/Home";
import React_Data from "../src/pages/React_Data";
import Geo from "../src/pages/Geo";
import Browser from './pages/Browser';
import Bluetooth from './pages/Bluetooth';
import Clipboard from './pages/clipboard';
import Storage from './pages/Storage';
import Blog from './pages/blog/Blog'
import Whatisip from './pages/blog/Whatisip';
import Whatisipv4 from './pages/blog/Whatisipv4';
import Whatisipv6 from './pages/blog/Whatisipv6';
import Test from './pages/Test'
import Dev from './pages/Dev'
import Navbarv2 from './utilities/Navbarv2/Navbarv2'
import Color from './pages/Color'
import Data from './pages/Data'
import About from './pages/About'
import Contactus from './pages/Contactus';


import ReactGA from 'react-ga';
import Iplookup from './pages/Iplookup';

ReactGA.initialize('UA-182725418-1')


/*
          <Route path="/blog" element={<Blog />} />
          <Route path="/test" element={<Navbarv2 />} />
          <Route path="/dev" element={<Dev />} />
          <Route path="/blog/whatisip" element={<Whatisipv4 />} />
          <Route path="/blog/whatisipv4" element={<Whatisipv4 />} />
          <Route path="/blog/whatisipv6" element={<Whatisipv6 />} />
          <Route path="/color" element={<Color />} />
*/


function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Ip_config_v2 />} />
          <Route path="/iplookup" element={<Iplookup />} />
          <Route path="/ip" element={<Ip_config_v2 />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/whatisip" element={<Whatisip />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contactus />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
