import React from 'react'

import { Container, Box, Typography, Grid } from '@mui/material'
import Navbarv3 from '../utilities/Navbarv3/Navbarv3'
import Link from '@mui/material/Link'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedinIcon from '@mui/icons-material/LinkedIn'

import Footerv2 from '../utilities/Footerv2'

function About() {

  return (
    <Container sx={{
      bgcolor: '#E7ECEF',
      pt: 2,
      pb: 2,
      mb: 2
    }}
    component="main" maxWidth="xl">
      <Navbarv3 />
      <Box sx={{
        mt: 2,
        display: 'flex',
        bgcolor: '#fff',
        alignItems: 'center'
      }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{
            p:2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center'
          }}>
          <Typography variant="h3" sx={{
            color: '#274C77',
            fontFamily: 'monospace',
            letterSpacing: '.1rem'
          }}>
            About Us
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{
            p:2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center'
          }}>
          <Typography variant="h6" sx={{
            color: '#274C77',
            fontFamily: 'monospace',
          }}>
            Iptooling.com was founded in 2022 to help people discover their public IP address for any means. We hope to develop more tools to help people discover the data that is available online, and help them discover ways to limit data shared online. We are young in our development, and hope to continue to iterate on the site to provide people with knowledge, education, and resources to help better protect them online. 
          </Typography>
          </Box>
          <Box sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center'
          }}>
            <Typography variant="h6" sx={{
              color: '#274C77',
              fontFamily: 'monospace',
            }}>
              This website also acts as a learning experiment for the lead developer. Iptooling.com is developed and maintained by Austin Hiller, who is testing different technologies for full stack development and infrastructure management. If you have any questions or want to reach out, please reach out via Linkedin or Twitter. 
            </Typography>
          </Box>
          <Box sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center'
          }}>
            <Typography variant="h6" sx={{
              color: '#274C77',
              fontFamily: 'monospace',
            }}>
              Data and Privacy documents are under curation currently as the website is in its infancy, with minimal users. No data is collected on users, and all data shown is collected on the client side of the application. The only data collected is via google analytics as we start to understand how the site is ranked and used in its infancy. 
            </Typography>
          </Box>
          <Box sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center'
          }}>
            <Typography variant="h6" sx={{
              color: '#274C77',
              fontFamily: 'monospace',
            }}>
              Iptooling.com uses IPStack to generate IP information client side. Website found below: https://ipstack.com/
            </Typography>
            <Link href="https://ipstack.com/" sx={{ color: '#274C77', fontFamily: 'monospace', letterSpacing: '.1rem', fontSize: 'large', fontWeight: 'bold' }} > 
              https://ipstack.com/
            </Link>
          </Box>
          <Box sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center'
          }}>
            <Typography variant="h6" sx={{
              color: '#274C77',
              fontFamily: 'monospace',
            }}>
              Reach out with below links! 
            </Typography>
            <Box sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center'
            }}>
              <Link href="https://twitter.com/hiller_austin" >
                <TwitterIcon sx={{color: "#274C77", size: 'large'}}/>
                Twitter
                <TwitterIcon sx={{color: "#274C77", size: 'large'}}/>
              </Link>
              <Link href="https://www.linkedin.com/in/austin-hiller-525816ba/">
                <LinkedinIcon sx={{color: "#274C77", size: 'large'}}/>
                Linkedin
                <LinkedinIcon sx={{color: "#274C77", size: 'large'}}/>
              </Link>
            </Box>

          </Box>
        </Grid>
      </Grid>
      </Box>  
      <Footerv2 />
    </Container>
  )
}

export default About