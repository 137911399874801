import React, { useEffect } from 'react'
import {useMemo, useState } from 'react'

import {GoogleMap, useLoadScript, MarkerF} from '@react-google-maps/api'


const containerStyle = {
  width: '100%',
  height: '100%'
}

const center = { 
  lat: 42,
  lng: -80
}

function Gmap(props) {

  const [location, setLocation] = useState(null)

  useEffect(() => {
    console.log("I see you peeping")
  }, [])

  const {isLoaded} = useLoadScript({
    googleMapsApiKey: 'AIzaSyDv1VDLeag4uuiwHY9A5BpCAWJlmjtTICY'
  })


  if (!isLoaded) return <div>Loading...</div>
  return (
    <Map location={props}/>
  )
}

function Map(location) {

  const center2 = {
    lat: location.location.lat,
    lng: location.location.lng
  }

  return (
    <GoogleMap
      zoom={12}
      center={center2}
      mapContainerStyle={containerStyle}
    >
      <MarkerF position={center2}/>
    </GoogleMap>
  )
}

export default (Gmap)