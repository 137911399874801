import React, { useEffect } from 'react'

import Navbar from '../../utilities/Navbar';

import { Container, Box } from '@mui/system';
import { Typography } from '@mui/material';

import ChatGPTFooter from '../../utilities/ChatGPTFooter';

import ReactGA from 'react-ga'

ReactGA.initialize('UA-182725418-1')

function Whatisipv4() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [] );

  
  return (
    <Container sx={{
      bgcolor: '#d1c4e9',
      pt: 2,
      pb: 2
    }}
    component="main" maxWidth="xl">
      <Navbar />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
        >
        <Typography variant="h3" color="#000">
          What is IPv4
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#fff',
          p: 4
        }}
        >
      <Typography variant="h4" color="#000">
      IPv4, short for Internet Protocol version 4, is the fourth version of the Internet Protocol (IP) and the first version to be widely deployed. IPv4 addresses are made up of four sets of numbers separated by dots, and they are usually written in decimal form (e.g., 192.168.1.1).
      <br/>
      <br/>
      IPv4 addresses are limited in the number of unique addresses that they can provide, which has led to the development of IPv6. IPv4 addresses are 32-bits in length, which means that they can provide approximately 4.3 billion unique addresses. This may seem like a lot, but the rapid growth of the internet has led to a shortage of IPv4 addresses in some parts of the world.
      <br/>
      <br/>
      In terms of how IPv4 addresses are structured, they are divided into two parts: the network portion and the host portion. This allows for the creation of subnetworks, which can be useful for organizing large networks. The network portion of an IPv4 address is represented by the first few bits, and it identifies the network that the device is connected to. The host portion of an IPv4 address is represented by the remaining bits, and it identifies the specific device on the network.
      <br/>
      <br/>
      IPv4 addresses are an essential part of the internet, and they play a crucial role in enabling communication between devices on a network. While the transition to IPv6 is underway, IPv4 is still the most widely used version of the Internet Protocol and is likely to remain so for the foreseeable future.
      </Typography>
      </Box>


      <ChatGPTFooter />
      </Container>
  )
}

export default Whatisipv4;