import React from 'react'

//MUI
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import DangerousIcon from '@mui/icons-material/Dangerous'
import Button from '@mui/material/Button'


function Footerv2() {
  
  const date = new Date().getFullYear()

  return (
    <Box sx={{ mt: 2, p:2, flexGrow: 1, background: '#fff'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'column'
          }}>
            <Button 
              size="medium"
              href='/about'
              sx={{ mx: 2, my: 2, color: '#274C77', fontFamily: 'monospace', letterSpacing: '.1rem', fontSize: 'large', fontWeight: 'bold'
              }}
              >
              About Us
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'column'
          }}>
            <Button 
              size="medium"
              href='/contact'
              sx={{ mx: 2, my: 2, color: '#274C77', fontFamily: 'monospace', letterSpacing: '.1rem', fontSize: 'large', fontWeight: 'bold'
              }}
              >
              Contact Us
            </Button>
          </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'column'
          }}>
            <Typography variant="h6" sx={{
              color: '#274C77',
              fontFamily: 'monospace',
              mx: '2',
              my: '2',
              mt: 2,
              letterSpacing: '.1rem'
            }}>
              iptooling.com {date} ©
            </Typography>
            
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footerv2;