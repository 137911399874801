import React from 'react';

//MUI
import Card from '@mui/material/Card';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import { CardContent, CardActions, CardMedia,  } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


import image1 from '../imagery/purple1.jpg'
import image2 from '../imagery/purple2.jpg'
import image3 from '../imagery/purple3.jpg'
import whatisipimg from '../pages/blog/images/blue-computer-closing-resize.jpg'
import whatisipv4img from '../pages/blog/images/blue-tech-resize.jpg'
import whatisipv6img from '../pages/blog/images/blue-keyboard-resize.jpg'

function Iphelper() {

  return (
    <Box sx={{ p: 2, mb: 2, flexGrow: 1}}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card sx={{minWidth: 275, p: 2, mb: 2}}>
            <CardMedia
              component="img"
              height="140"
              image={whatisipimg}
            />
            <CardContent>
              <Typography variant="h5" color="#000" gutterBottom sx={{
                color: '#274C77',
                fontFamily: 'monospace',
              }}>
                What is an IP Address
              </Typography>
              <Typography variant="body1" color="#000" gutterBottom sx={{
                color: '#274C77',
                fontFamily: 'monospace',
              }}>
                The Internet Protocol Address is important for identification and connection. 
                <br />
              </Typography>
              </CardContent>
              <CardActions>
                <Button sx={{
                  background: '#274C77', 
                  '&:hover': {
                    backgroundColor: '#274C77'
                  }
                }}
                size="medium"
                variant="contained"
                href="/blog/whatisip"
                >
                  Learn More
                </Button>
              </CardActions>
          </Card>
        </Grid>
      
    
      </Grid>


    </Box>


  )

}

export default Iphelper