import React, { useEffect, useState } from "react";

//material-ui
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Typography } from "@mui/material";
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'

import axios from 'axios';

import Gmap from "../helpers/Gmap";
import Ipdata from "../helpers/Ipdata";

import Navbarv3 from "../utilities/Navbarv3/Navbarv3";
import Iphelper from "../helpers/Iphelper";
import Footerv2 from "../utilities/Footerv2";

import ReactGA from 'react-ga';

ReactGA.initialize('UA-182725418-1')

function Ip_config_v2() {

  const [isLoaded, setLoading] = useState(false)

  const [longitude, setLongitude] = useState(null)
  const [latitude, setLatitude] = useState(null)
  const [ipv4, setIpv4] = useState(null)
  const [ipv6, setIpv6] = useState(null)
  const [ip, setIp] = useState(null)
  const [isp, setIsp] = useState(null)
  const [city, setCity] = useState(null)
  const [state, setState] = useState(null)
  const [zip, setZip] = useState(null)
  const [fulldata, setfulldata] = useState(null)

  const getIP = async (event) => {
    try {
      const res = await axios.get('https://api.ipstack.com/check?access_key=254fd172f90e8fa6f9bbd2c9657567c8')
      setIp(res.data.ip)
      setIsp(res.data.connection.isp)
      setCity(res.data.city)
      setState(res.data.region_code)
      setZip(res.data.zip)
      setLoading(true)
      setLatitude(res.data.latitude)
      setLongitude(res.data.longitude)
      setfulldata(res.data)
    } catch(err) {
      console.log(err)
    }
  }

  const getGeo = () => {
    navigator.geolocation.getCurrentPosition(function(position) {
      console.log("latitude is: ", position.coords.latitude)
      console.log("longitude is: ", position.coords.longitude)
      setLongitude(position.coords.longitude)
      setLatitude(position.coords.latitude)
      setLoading(true)
    });
  }

  useEffect(() => {
    //getGeo()
    getIP()
    ReactGA.pageview(window.location.pathname + window.location.search)

  }, [] );

  if(!isLoaded) return <div>Loading...</div>
  return (

    <Container sx={{
      bgcolor: '#E7ECEF',
      pt: 2,
      pb: 2,
      mb: 2
      }}
      component="main" maxWidth="xl">
      <Navbarv3 />
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#fff'
        }}
        >
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box sx={{ 
              p: 2,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
              }}>
              <Typography variant="h4" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
                letterSpacing: '.1rem'
              }}>
                Finding Your IP Address
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{p: 2, height: '250px'}}>
              <Box sx={{p: 2, backgroundColor: '#E7ECEF', mb: 2, borderRadius: '4px'}}>
                <Typography sx={{
                  fontFamily: 'monospace',
                  letterSpacing: '.1rem',
                }}>
                  {'>'} IP: {ip}
                </Typography>
              </Box>
              <Box sx={{p: 2, backgroundColor: '#E7ECEF', mb: 2, borderRadius: '4px'}}>
                <Typography sx={{
                  fontFamily: 'monospace',
                  letterSpacing: '.1rem',
                }}>
                  {'>'} ISP: {isp}
                </Typography>
              </Box>
              <Box sx={{p: 2, backgroundColor: '#E7ECEF', mb: 2, borderRadius: '4px'}}>
                <Typography sx={{
                  fontFamily: 'monospace',
                  letterSpacing: '.1rem',
                }}>
                  {'>'} Location: {city} , {state} , {zip}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{ p:2, height: '250px', mb: 2, mr: 2}}>
              <Gmap lat={latitude} lng={longitude}/>
            </Box>
          </Grid>
        </Grid>


        </Box>

        <Ipdata data={fulldata} />

        <Footerv2 />

      </Container>

      //<Gmap lat={latitude} lng={longitude}/>

  )
}

export default Ip_config_v2;