import React, {useEffect, useState} from 'react'

import { Container, Grid, Button, Typography, Box, TextField } from '@mui/material'
import Navbarv3 from '../utilities/Navbarv3/Navbarv3'
import Footerv2 from '../utilities/Footerv2'
import SelectInput from '@mui/material/Select/SelectInput'

import axios from 'axios'

function Contactus() {

  const [fullName, setfullName] = useState('')
  const [emailAddress, setemailAddress] = useState('')
  const [message, setMessage] = useState('')
  const [input, setInput] = useState('')

  var formData = new FormData()

  const handleSubmit = async (event) => {
    try {
      formData.append("full_name", fullName)
      formData.append("email", emailAddress)
      formData.append("message", message)

      const res = axios.post("https://quiet-bridge-2164.tines.com/webhook/a131d04bf996a31501e2c8badfb5f6fc/74d70e9a6f21e35aa58b7bb738ed4960", formData)
      console.log(res)
      setfullName('')
      setemailAddress('')
      setMessage('')
      formData.delete("full_name")
      formData.delete("email")
      formData.delete("message")

    } catch (e) {
      console.log(e)
    }
  }

  const update = (e) => {
    SelectInput(e.target.value)
    console.log(input)
  }

  return (

    <Container sx={{
      bgcolor: '#E7ECEF',
      pt: 2,
      pb: 2,
      mb: 2,
    }} coomponent="main" maxWidth="xl">
      <Navbarv3 />
      <Box sx={{
        mt: 2,
        display: 'flex',
        bgcolor: '#fff',
        alignItems: 'center'
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center'
            }}>
              <Typography variant="h3" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
                letterSpacing: '.1rem'
              }}>
                Contact Us
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center',
            }}>
              <Typography variant="h6" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
                letterSpacing: '.1rem'
              }}>
                Name
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center',
            }}>
              <TextField onChange={e => setfullName(e.target.value)}>

              </TextField>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center',
            }}>
              <Typography variant="h6" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
                letterSpacing: '.1rem'
              }}>
                Email
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center',
            }}>
              <TextField onChange={e => setemailAddress(e.target.value)}>

              </TextField>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center',

            }}>
              <Typography variant="h6" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
                letterSpacing: '.1rem'
              }}>
                Message
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center',
            }}>
              <TextField onChange={e => setMessage(e.target.value)}>

              </TextField>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center',
            }}>
              <Button sx={{
                background: '#274C77',
                fontFamily: 'monospace',
                letterSpacing: '.1rem',
                '&hover': {
                  backgroundColor: '#274C77'
                }
              }}
              size="medium"
              variant="contained"
              onClick={handleSubmit}
              >
                Send
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footerv2 />
    </Container>
  )
}

export default Contactus