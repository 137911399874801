import React, { useEffect, useState } from 'react'

import Navbarv3 from '../utilities/Navbarv3/Navbarv3';
import Footer from '../utilities/Footer';

import ReactGA from 'react-ga';

import axios from 'axios'

import Gmap from '../helpers/Gmap'
import Ipdata from '../helpers/Ipdata';

//MUI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Typography, TextField, Button, DialogContent } from '@mui/material';
import Footerv2 from '../utilities/Footerv2';

ReactGA.initialize('UA-182725418-1')

function Iplookup() {

  const [isLoaded, setLoading] = useState(true)


  const [iperror, setIperror] = useState(false)
  const [input, setInput] = useState('')
  const [show, setShow] = useState(false)
  const [longitude, setLongitude] = useState(null)
  const [latitude, setLatitude] = useState(null)
  const [ip, setIp] = useState(null)
  const [isp, setIsp] = useState(null)
  const [city, setCity] = useState(null)
  const [state, setState] = useState(null)
  const [zip, setZip] = useState(null)
  const [fulldata, setfulldata] = useState(null)


  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)

  })

  const handleSubmit = async (event) => {
    console.log('https://api.ipstack.com/' + input +'?access_key=254fd172f90e8fa6f9bbd2c9657567c8')
    try {
      setLoading(false)
      const res = await axios.get('https://api.ipstack.com/' + input +'?access_key=254fd172f90e8fa6f9bbd2c9657567c8')
      console.log(res)
      setIp(res.data.ip)
      setIsp(res.data.connection.isp)
      setCity(res.data.city)
      setState(res.data.region_code)
      setZip(res.data.zip)
      setLoading(true)
      setLatitude(res.data.latitude)
      setLongitude(res.data.longitude)
      setfulldata(res.data)
      console.log(res.data)
      setLoading(true)
      setShow(true)
      setIperror(false)
    } catch (err) {
      console.log(err)
      console.log("ERROR")
      setLoading(true)
      setIperror(true)
      clearData()
    }
  }

  const clearData = () => {
    setIp("")
    setIsp("")
    setCity("")
    setState("")
    setZip("")
    setLatitude("")
    setLongitude("")
    setfulldata("")
    setShow(false)
  }

  const update = (e) => {
    setInput(e.target.value)
    console.log(input)
  }

  if(!isLoaded) return <div>Loading...</div>

  return (
    <Container sx={{
      bgcolor: '#E7ECEF',
      pt: 2,
      pb: 2,
      mb: 2
    }}
    component="main" maxWidth="xl">
      <Navbarv3 />
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
        >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
              <Typography variant="h4" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
                letterSpacing: '.1rem',
              }}>
                IP Lookup Table
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box justifyContent="center" display="flex" sx={{p:2}}>
              <TextField id="outlined-basic" label="Await IP Input" onChange={e => update(e)} variant="outlined" sx={{
                '&:hover fieldset': {
                  borderColor: 'yellow'
                },
                fontFamily: 'monospace',
                letterSpacing: '.1rem'
              }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box justifyContent="center" display="flex" sx={{p:2}}>
              <Button sx={{
                  background: '#274C77',
                  fontFamily: 'monospace',
                  letterSpacing: '.1rem', 
                  '&:hover': {
                    backgroundColor: '#274C77'
                  }
                }}
                size="medium"
                variant="contained"
                onClick={handleSubmit}>
                  Submit
                </Button>
            </Box>
          </Grid>
          {
            show &&
            <Container sx={{
              bgcolor: '#E5EDF1',
              pt: 2,
              pb: 2,
              mb: 2
            }} component="main" maxWidth="xl">
            
            <Box sx={{
              marginTop: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#fff'
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#fff'
                  }}>
                    <Typography variant="h4" sx={{
                      color: '#274C77',
                      fontFamily: 'monospace',
                      letterSpacing: '.1rem'
                    }}>
                      IP Address Found
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{p:2, height: '250px'}}>
                  <Box sx={{p: 2, backgroundColor: '#E7ECEF', mb: 2, borderRadius: '4px'}}>
                    <Typography sx={{
                      fontFamily: 'monospace',
                      letterSpacing: '.1rem',              
                    }}>
                      {'>'} IP: {ip}
                    </Typography>
                  </Box>
                  <Box sx={{p: 2, backgroundColor: '#E7ECEF', mb: 2, borderRadius: '4px'}}>
                    <Typography sx={{
                      fontFamily: 'monospace',
                      letterSpacing: '.1rem', 
                    }}>
                      {'>'} ISP: {isp}
                    </Typography>
                  </Box>
                  <Box sx={{p: 2, backgroundColor: '#E7ECEF', mb: 2, borderRadius: '4px'}}>
                    <Typography sx={{
                      fontFamily: 'monospace',
                      letterSpacing: '.1rem',
                    }}>
                      {'>'} Location: {city} , {state} , {zip}
                    </Typography>
                  </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ p:2, height: '250px', mb: 2, mr: 2}}>
                    <Gmap lat={latitude} lng={longitude}/>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Ipdata data={fulldata} />
            </Container>



            
          }

          { iperror && 

            <Container sx={{
                bgcolor: '#E5EDF1',
                pt: 2,
                pb: 2,
                mb: 2
              }} component="main" maxWidth="xl">
              <Box sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#fff'
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}>
                      <Typography variant="h4" sx={{
                        color: '#274C77',
                        fontFamily: 'monospace',
                        letterSpacing: '.1rem',
                      }}>
                        IP input not valid
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

            </Container>
          }

        </Grid>
        </Box>
        <Footerv2 />
      </Container>
  )


}

export default Iplookup;
