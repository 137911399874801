import React from 'react';

//MUI
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { TableBody, TableCell, TableContainer, TableRow, Typography, Paper, Table, TableHead } from '@mui/material';

//#96C2DB
//#E5EDF1

function createData(name, content) {
  return {name, content}
}



function Ipdata(data) {

  const rows = [
    createData('ipv4', data.data.ip),
    createData('location', data.data.city + " , " + data.data.region_code + " , " + data.data.zip),
    createData('country', data.data.continent_name),
    createData('lat/long', data.data.latitude.toFixed(2) + " , " + data.data.longitude.toFixed(2)),
    createData('language', data.data.location.languages[0].name),
    createData("timezone", data.data.time_zone.code),
    createData('currency', data.data.currency.code + ' , (' + data.data.currency.symbol + ')'),
    createData('Internet Provider', data.data.connection.isp)
  ]

  console.log(data)



  return (
    <Box sx={{ p:2, mb:2, mt:2, flexGrow: 1, bgcolor: '#fff'}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Box sx={{ 
              p: 2,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
              }}>
              <Typography variant="h4" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
                letterSpacing: '.1rem'
              }}>
                IP Data
              </Typography>
            </Box>
        </Grid>

        <Grid item md={6} xs={12}>
          <Box sx={{
            bgcolor: '#E7ECEF'
          }}>
            <TableContainer component={Paper}>
              <Table sx={{minWidth: 650, fontFamily: 'monospace', bgcolor:'#E7ECEF', borderRadius: 2}} size="small" aria-label="IP data">
                <TableHead>
                  <TableRow sx={{
                    fontFamily: 'monospace'
                  }}>
                    <TableCell sx={{fontFamily: 'monospace', fontWeight: 'bold'}}>Name</TableCell>
                    <TableCell sx={{fontFamily: 'monospace', fontWeight: 'bold'}}>Content</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                      >
                      <TableCell component="th" scope="row" sx={{fontFamily: 'monospace'}}>
                        {row.name}
                      </TableCell>
                      <TableCell align="left" sx={{fontFamily: 'monospace'}}>{row.content}</TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{
            bgcolor: '#E7ECEF',
            display: 'flex',
            wordWrap: 'break-word',
            borderRadius: 2,
            p: 1,
            mr: 2
          }}>
            <Typography  sx={{
              fontFamily: 'monospace',
              wordWrap: 'break-word',
              fontSize: '16px'
            }}>
              {JSON.stringify(data, null, 2)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )

}

export default Ipdata