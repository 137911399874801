import React from 'react'

//MUI
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import DangerousIcon from '@mui/icons-material/Dangerous'

function Footer() {
  
  const date = new Date().getFullYear()

  return (
    <Box sx={{ mt: 2, p:2, flexGrow: 1, background: '#fff'}}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box display="flex" alignItems="center" justifyContent="center" sx={{p:2}}>
            <DangerousIcon sx={{ display: {xs: 'none', md: 'flex'}, mr: 1, color: '#274C77'}} />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" alignItems="center" justifyContent="center" sx={{p:2}}>
            <Typography variant="h6" sx={{ 
              color: '#274C77',
              fontFamily: 'monospace',
              letterSpacing: '.1rem',
              }}>
              Use At Own Risk ©  
            </Typography>
            <Typography variant="h6" sx={{ 
              color: '#274C77',                       
              fontFamily: 'monospace',
              letterSpacing: '.1rem',
              }}>
              {date}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" alignItems="center" justifyContent="center" sx={{p:2}}>
            <DangerousIcon sx={{ display: {xs: 'none', md: 'flex'}, mr: 1, color: '#274C77'}} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer;