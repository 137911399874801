import React, { useEffect } from 'react'

import Navbar from '../../utilities/Navbar';

import { Container, Box } from '@mui/system';
import { Typography } from '@mui/material';

import ChatGPTFooter from '../../utilities/ChatGPTFooter';

import ReactGA from 'react-ga'

ReactGA.initialize('UA-182725418-1')

function Whatisipv6() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [] );
  
  return (
    <Container sx={{
      bgcolor: '#d1c4e9',
      pt: 2,
      pb: 2
    }}
    component="main" maxWidth="xl">
      <Navbar />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
        >
        <Typography variant="h3" color="#000">
          What is an IPv6
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#fff',
          p: 4
        }}
        >
      <Typography variant="h4" color="#000">
      IPv6, short for Internet Protocol version 6, is the successor to IPv4 and is designed to address the limitations of IPv4. IPv6 addresses are made up of eight sets of numbers separated by colons, and they are usually written in hexadecimal form (e.g., 2001:0db8:85a3:0000:0000:8a2e:0370:7334).
      <br/>
      <br/>
      One of the main differences between IPv4 and IPv6 is the number of unique addresses that they can provide. IPv4 addresses are 32-bits in length, which means that they can provide approximately 4.3 billion unique addresses. IPv6 addresses, on the other hand, are 128-bits in length, which means that they can provide approximately 3.4 x 10^38 unique addresses. This is a significantly larger number, and it means that IPv6 can accommodate the growing number of devices that are connected to the internet.
      <br/>
      <br/>
      Another key difference between IPv4 and IPv6 is the way that they are structured. IPv4 addresses are divided into two parts: the network portion and the host portion. This allows for the creation of subnetworks, which can be useful for organizing large networks. IPv6 addresses, on the other hand, are divided into three parts: the network prefix, the subnet ID, and the interface ID. This allows for even more flexible network organization.
      <br/>
      <br/>
      In terms of compatibility, IPv6 is not directly backwards compatible with IPv4. This means that devices that are using IPv4 addresses cannot communicate directly with devices that are using IPv6 addresses. However, there are mechanisms in place that allow IPv6 and IPv4 devices to communicate with each other, such as network address translation (NAT) and tunneling.
      <br/>
      <br/>
      In conclusion, IPv6 is the successor to IPv4 and is designed to address the limitations of IPv4, including the number of unique addresses that can be provided. While the transition to IPv6 is ongoing, IPv4 is still the most widely used version of the Internet Protocol and is likely to remain so for the foreseeable future.
      </Typography>
      </Box>


      <ChatGPTFooter />
      </Container>
  )
}

export default Whatisipv6;