import React from 'react'

import Layout from '../../layout/Layout';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Typography, Grid } from '@mui/material';

import Iphelper from '../../helpers/Iphelper';

import Navbar from '../../utilities/Navbar';
import Navbarv3 from '../../utilities/Navbarv3/Navbarv3';

import image from './images/blue-computer-closing-resize.jpg'
import Footerv2 from '../../utilities/Footerv2';


function Whatisip() {

  return (
    <Container sx={{
      bgcolor: '#E7ECEF',
      pt: 2,
      pb: 2,
      mb: 2,
    }}
    component="main" maxWidth="xl">
      <Navbarv3 />
      <Box sx={{
        mt: 2, 
        display: 'flex',
        bgcolor: '#fff',
        alignItems: 'center'
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              flexDirection: 'column',
            }}>
              <Typography variant="h3" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
                letterSpacing: '.1rem'
              }}>
                What Is An IP Address
              </Typography>
            </Box>

            </Grid>
            <Grid item xs={12}>
            <Box sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center'
            }}>
              <Typography variant="h4" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
              }}>
                What is an IP Address
              </Typography>  
            </Box>
            <Box sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center'
            }}>
              <Typography variant="h6" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
              }}>
                An internet protocol address, or IP address is a numerical reference for a device connected to a network. The purpose of an IP address is to identify and locate the host inside a network, as networks can be quite large. An IP address is like your home address, it identifies the location of your property, with the understanding that your home may have different occupants throughout its lifetime.  
              </Typography>  
            </Box>
            <Box sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center'

            }}>
              <Typography variant="h4" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
              }}>
                Public vs. Private IP Address
              </Typography>  
            </Box>
            <Box sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center'
            }}>
              <Typography variant="h6" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
              }}>
                It is important to understand that a device connected to the internet has 2 IP addresses, a public IP and a private IP. 
              </Typography>  
            </Box>
            <Box sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center'
            }}>
              <Typography variant="h6" sx={{
                color: '#274C77',
                fontFamily: 'monospace',

              }}>
                Your private IP is generated and leased by your router, and often needs a password (wifi password) to get assigned to a device.              </Typography>  
            </Box>
            <Box sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center'
            }}>
              <Typography variant="h6" sx={{
                color: '#274C77',
                fontFamily: 'monospace',
              }}>
                Your public IP address is the address of your internet modem (often bundled with your router) that connects you to the internet through your internet service provider. This address is what internet companies can see as you connect to websites worldwide.              </Typography>  
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footerv2 />
    </Container>
  )
}

export default Whatisip