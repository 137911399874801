import * as React from 'react';

//MUI
import AppBar from '@mui/material/AppBar';
import AdbIcon from '@mui/icons-material/Adb';
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import PropTypes from 'prop-types';


import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import SecurityIcon from '@mui/icons-material/Security'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';


const drawerWidth = 240;
const navItems = ['ip', 'iplookup'];

function Navbarv3(props) {

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" href="/" sx={{ 
        my: 2,
        color: '#274C77',
        letterSpacing: '.3rem',
        fontFamily: 'monospace' 
        }}>
        IPTOOLING
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton href={item} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} sx={{
                color: '#274C77',
                letterSpacing: '.3rem',
                fontFamily: 'monospace' 
              }}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;


  return (
    <Box sx={{display: 'flex'}}>
    <AppBar component="nav" position="static" sx={{background: '#fff'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, color: '#96C2DB', display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton >
          <SecurityIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, color: "#274C77" }} />
          <Typography
            variant="h5"
            noWrap
            component="div"
            href="/"
            sx={{ 
              color: '#274C77', 
              letterSpacing: '.3rem', 
              fontFamily: 'monospace', 
              flexGrow: 1, 
              display: { xs: 'none', sm: 'block' } }}
          >
            IPTOOLING
          </Typography>
          <Box sx={{ flexFrow:1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                key="test"
                variant="contained"
                size="large"
                href='/'
                sx={{ mx: 2, my: 2, color: '#fff', fontFamily: 'monospace', letterSpacing: '.1rem', fontSize: 'medium', background: '#274C77', '&:hover': {
                  backgroundColor: '#274C77'
                }}}
              >
                IP
              </Button>

              <Button
                key="test2"
                variant="contained"
                size="large"
                href='/iplookup'
                sx={{ mx: 2, my: 2, color: '#fff', fontFamily: 'monospace', letterSpacing: '.1rem', fontSize: 'medium', background: '#274C77', '&:hover': {
                  backgroundColor: '#274C77'
                }}}
              >
                IP Lookup
              </Button>

              <Button
                key="test3"
                variant="contained"
                size="large"
                href='/blog'
                sx={{ mx: 2, my: 2, color: '#fff', fontFamily: 'monospace', letterSpacing: '.1rem', fontSize: 'medium', background: '#274C77', '&:hover': {
                  backgroundColor: '#274C77'
                }}}
              >
                Blog
              </Button>

            </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      </Box>
  )
}

export default Navbarv3